export const Apps = [
	{ value: 'APP_CLIENT', label: 'APP' },
	{ value: 'PRP', label: 'PRP' }
]

export function handleApps(selectedApp: string) {
	if (!selectedApp) return '-'
	const apps = Apps.filter((app) => app.value === selectedApp)
	return apps[0]?.label || '-'
}

export const roles = [
	{ id: 1, label: 'Administrador DE' },
	{ id: 3, label: 'Recursos Humanos' },
	{ id: 2, label: 'Usuário de serviços técnicos' },
	{ id: 4, label: 'Administrador de Serviços' },
	{ id: 12, label: 'Administrador de Técnicos' },
	{ id: 5, label: 'Usuário de pedidos' },
	{ id: 7, label: 'Administrador de pedidos' },
	{ id: 9, label: 'Usuário de pedidos de peças' },
	{ id: 11, label: 'Cartão de reconhecimento' },
	{ id: 10, label: 'Universidade Prática' },
	{ id: 13, label: 'Bloqueio de Cliente' },
	{ id: 14, label: 'Anexos Financeiro' },
	{ id: 15, label: 'Anexos Expedição' },
	{ id: 16, label: 'Anexos Adm. Pedidos' }
]
