import logo from 'assets/image/new-logo-branco.webp'
import logoWithText from 'assets/image/new-logo-prp.webp'
import User from 'models/User'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { RecognitionCardProvider } from 'module/recognitionCard/context/recognitionCardContext'
import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getEmployees, getPermissions, userInfo } from 'services'
import ConfigUserModal from 'shared/ConfigUserModal'
import UserDropdown from 'shared/UserDropdown'
import VersionInfo from 'shared/VersionInfo'
import { store } from 'store'
import { saveRoles } from 'store/modules/user/actions'
import AdminSystemCard from './adminSystemCard'
import HumanResourcesCard from './humanResourcesCard'
import OrderManagementCard from './orderManagementCard'
import RecognitionCard from './recognitionCard'
import './styles.scss'
import TechnicalServicesCard from './technicalServicesCard'
import UniversityCard from './universityCard'

type TParams = { history: string }

const FeaturesPage: React.FC<RouteComponentProps<TParams>> = ({
	history,
	match
}: RouteComponentProps<TParams>) => {
	const { viewType } = useContext(DeviceContextElement)
	const [showConfigModal, setShowConfigModal] = useState<boolean>(false)
	const [employeesList, setEmployeesList] = useState<any[]>()
	const [user, setUser] = useState<User>()
	const userStore = store.getState().user
	const [userRoles, setUserRoles] = useState<string[]>(userStore.roles)
	const dispatch = useDispatch()

	useEffect(() => {
		const callOpenApi = () => {
			getPermissions(userStore.profile)
				.then((response) => {
					const { data } = response
					const roles: string[] = []
					data.map((permission: any) => {
						roles.push(permission.role.name)
					})
					setUserRoles(roles)
					dispatch(saveRoles(roles))
				})
				.catch((err) => {
					console.log(err)
				})
		}
		callOpenApi()
	}, [history, match, user])

	useEffect(() => {
		getEmployees()
			.then((response) => {
				setEmployeesList(response.data.employeesList)
			})
			.catch((error) => console.log(error))
	}, [])

	useEffect(() => {
		if (!user) {
			getUserInfo()
		}
	}, [user])

	const getUserInfo = () => {
		userInfo(userStore.profile)
			.then((res) => {
				setUser(res.data)
			})
			.catch(() => {
				toast.error('Houve um erro ao buscar as informações do usuário!')
			})
	}

	const configModalContent = () => {
		return user ? (
			<div className="modal user-config">
				<ConfigUserModal
					onClose={handleConfigModalToggle}
					onUpdateUser={handleUserDataUpdate}
				/>
			</div>
		) : (
			<></>
		)
	}

	const handleConfigModalToggle = () => {
		setShowConfigModal(!showConfigModal)
	}

	const handleUserDataUpdate = (userInfo: User) => {
		const userData = { ...user } as User

		userData.name = userInfo.name
		userData.surname = userInfo.surname
		userData.cel = userInfo.cel

		setUser(userData)
	}

	return (
		<div className="features-home">
			<div className="features-home-header">
				<img
					src={viewType === 'mobile' ? logo : logoWithText}
					alt="Prática logo icon"
					className="pratica-logo-icon"
				/>
				<UserDropdown />
				{showConfigModal ? configModalContent() : <></>}
			</div>
			<div className="features-home-main-contain">
				<div className="cards-group ">
					{userRoles ? (
						userRoles.map((role: string) => {
							switch (role) {
								case 'fieldServiceUser':
									return <TechnicalServicesCard key={role} />
								case 'humanResourcesUser':
									return (
										<HumanResourcesCard
											employeesList={employeesList}
											key={role}
										/>
									)
								case 'orderUser':
									return <OrderManagementCard key={role} />
								case 'universityPratica':
									return <UniversityCard key={role} />
								case 'recognitionCard':
									return (
										<RecognitionCardProvider>
											<RecognitionCard
												employeesList={employeesList}
												key={role}
											/>
										</RecognitionCardProvider>
									)
								case 'digitalEndeavourManager':
									return userStore.email !==
										'praticaapi@fieldcontrol.com.br' ? (
										<AdminSystemCard key={role} />
									) : (
										<></>
									)
								default:
									return null
							}
						})
					) : (
						<div className="feature-card " key={99}>
							<Skeleton className="featureCard" count={1} height={50} />
							<br />
							<Skeleton className="featureCard" count={4} height={22} />
							<br />
							<br />
							<Skeleton className="featureCard" count={1} height={35} />
						</div>
					)}
				</div>
			</div>
			<VersionInfo />
		</div>
	)
}
export default FeaturesPage
