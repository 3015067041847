import Technician from 'models/Technician'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import {
	registerEmployeeFieldControl,
	requestCompanyListFieldControl
} from 'services'
import { IFormInput } from '..'
import {
	ButtonContainer,
	ButtonIntegrate,
	Div1,
	DivCenter,
	DivContainer,
	DivLeft,
	DivRight,
	Input,
	LabelName,
	Select
} from '../styles'

interface Props {
	techEdit?: Technician
	changeName: any
	changeEmail1: any
	techForm: UseFormReturn<IFormInput, any, any>
	onChangeTechnicianProfile: (tecProfile: string) => void
	onChangeTechnicianCompany: (tecCompany: any) => void
	onChangeTechnicianEmployee: (tecEmployee: string) => void
	fieldServiceTechnicianManager: boolean
}

const technicianProfiles = [
	{ value: '', label: '' },
	{ value: 'Field_chef_autonomous', label: 'Chef Autônomo' },
	{ value: 'Field_company', label: 'Empresa' },
	{ value: 'Field_tec_autonomous', label: 'Técnico Autônomo' }
]

const TechnicianFieldControl = ({
	techEdit,
	changeName,
	changeEmail1,
	techForm,
	onChangeTechnicianProfile,
	onChangeTechnicianCompany,
	onChangeTechnicianEmployee,
	fieldServiceTechnicianManager
}: Props) => {
	const [listCompany, setListCompany] = useState<any[]>([])
	const [companyIdFC, setCompanyIdFC] = useState<any>(techEdit?.company_id_fc)
	const [profileFieldControl, setProfileFieldControl] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		if (techEdit !== undefined) {
			setProfileFieldControl(techEdit?.technician_profile)
			selectProfileFC(techEdit?.technician_profile)
			onChangeTechnicianEmployee(techEdit.employee_id_fc)
		} else if (techEdit === undefined) {
			selectProfileFC(profileFieldControl)
		}
	}, [listCompany])

	const {
		setValue,
		formState: { errors },
		register,
		watch
	} = techForm

	const watchEmail = watch('email')

	useEffect(() => {
		if (watchEmail !== techEdit?.email) {
			setValue('employee_id_fc', '')
		} else if (watchEmail === techEdit?.email) {
			setValue('employee_id_fc', techEdit?.employee_id_fc)
		}
	}, [watchEmail, profileFieldControl])

	useEffect(() => {
		requestCompanyListFieldControl()
			.then((res) => {
				const listArrayCompany = res.data.response.companies
				const companyNull = { id: '', name: '' }
				listArrayCompany.push(companyNull)
				setListCompany(listArrayCompany)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	const selectCompanyFC = (tecCompany: any) => {
		setCompanyIdFC(tecCompany)
		onChangeTechnicianCompany(tecCompany)
	}

	const selectProfileFC = (tecProfile: any) => {
		setProfileFieldControl(tecProfile)
		switch (tecProfile) {
			case 'Field_tec_autonomous':
				onChangeTechnicianProfile(tecProfile)
				onChangeTechnicianCompany(
					process.env.REACT_APP_COMPANY_ID_TEC_PRATICA_FIELD_CONTROL
				)
				$('#company_id_fc').val(
					`${process.env.REACT_APP_COMPANY_ID_TEC_PRATICA_FIELD_CONTROL}`
				)
				$('#company_id_fc').prop('disabled', true)
				break
			case 'Field_chef_autonomous':
				onChangeTechnicianProfile(tecProfile)
				onChangeTechnicianCompany(
					process.env.REACT_APP_COMPANY_ID_CHEF_PRATICA_FIELD_CONTROL
				)
				$('#company_id_fc').val(
					`${process.env.REACT_APP_COMPANY_ID_CHEF_PRATICA_FIELD_CONTROL}`
				)
				$('#company_id_fc').prop('disabled', true)
				break
			case 'Field_company':
				onChangeTechnicianProfile(tecProfile)
				onChangeTechnicianEmployee('')
				onChangeTechnicianCompany(companyIdFC)
				$('#company_id_fc').val(
					techEdit?.company_id_fc === undefined ? '' : techEdit?.company_id_fc
				)
				$('#employee_id_fc').val('')
				$('#company_id_fc').prop('disabled', false)
				break
			default:
				onChangeTechnicianProfile('')
				onChangeTechnicianEmployee('')
				onChangeTechnicianCompany('')
				$('#company_id_fc').val('')
				$('#employee_id_fc').val('')
				$('#company_id_fc').prop('disabled', true)
				break
		}
	}

	const onIntegrar = async (data: any) => {
		setLoading(true)
		registerEmployeeFieldControl(data)
			.then((res) => {
				if (res.data.statusCode === 422) {
					toast.error('Houve um erro ao integrar colaborador.')
				} else {
					const employeeId = res.data.response.id
					onChangeTechnicianEmployee(employeeId)
					setValue('employee_id_fc', employeeId)
					setLoading(false)
					toast.success('Colaborador integrado com sucesso.')
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleAction = () => {
		const techFieldControl = { changeName, changeEmail1, profileFieldControl }

		return changeName &&
			changeEmail1 &&
			profileFieldControl !== 'Field_company' &&
			profileFieldControl &&
			fieldServiceTechnicianManager ? (
			<ButtonIntegrate
				type="button"
				onClick={() => onIntegrar(techFieldControl)}
				disabled={loading}
			>
				Integrar
			</ButtonIntegrate>
		) : (
			<ButtonIntegrate type="button" disabled>
				Integrar
			</ButtonIntegrate>
		)
	}

	return (
		<>
			<DivContainer>
				<DivLeft>
					<Div1>
						<LabelName>Perfil Field Control</LabelName>
						<Select
							id="technician_profile"
							name="technician_profile"
							defaultValue={
								techEdit?.technician_profile === undefined
									? undefined
									: techEdit?.technician_profile
							}
							onChange={(e: any) => selectProfileFC(e.target.value)}
						>
							{technicianProfiles.map((item) => (
								<option key={item.value} value={item.value}>
									{item.label}
								</option>
							))}
						</Select>
					</Div1>
				</DivLeft>
				<DivCenter id="companyFC">
					<Div1>
						<LabelName>Empresa Field Control</LabelName>
						<Select
							name="company_id_fc"
							id="company_id_fc"
							disabled
							defaultValue={
								techEdit?.company_id_fc === undefined
									? undefined
									: techEdit?.company_id_fc
							}
							onChange={(e: any) => selectCompanyFC(e.target.value)}
						>
							{listCompany.map((item) => (
								<option key={item.id} value={item.id}>
									{item.name}
								</option>
							))}
						</Select>
					</Div1>
				</DivCenter>
				<DivRight id="employeeFC">
					<Div1>
						<LabelName>Colaborador Field Control</LabelName>
						<Input
							disabled
							id="employee_id_fc"
							{...register('employee_id_fc', {
								required: {
									value: !!(
										profileFieldControl === 'Field_chef_autonomous' ||
										profileFieldControl === 'Field_tec_autonomous'
									),
									message: 'O campo é obrigatório, clique em integrar.'
								}
							})}
							placeholder="Colaborador"
						/>
					</Div1>
					<small>
						{errors.employee_id_fc && (
							<span style={{ color: 'red', display: 'flex', margin: 'auto' }}>
								{errors.employee_id_fc.message}
							</span>
						)}
					</small>
				</DivRight>
			</DivContainer>
			<DivContainer>
				<ButtonContainer>{handleAction()}</ButtonContainer>
			</DivContainer>
		</>
	)
}

export default TechnicianFieldControl
