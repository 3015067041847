import { z } from 'zod'

function removerAcentos(texto: string): string {
	return texto
		.normalize('NFD')
		.replace(/[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e]/g, '')
}

export const updateClientSchema = z.object({
	sameBillingAddress: z.boolean().default(true),
	a1Classif: z.string().default(''),
	a1Cgc: z
		.string()
		.min(11, 'O campo CNPJ/CPF está incompleto!')
		.max(18, 'O campo CNPJ/CPF deve ter no máximo 18 caracteres')
		.transform((value) => value?.replace(/\D/g, '')),
	a1CodMun: z.string().min(1, 'O campo Código do município é obrigatório!'),
	a1Conta: z.string().min(1, 'O campo Conta contábil é obrigatório!'),
	a1Est: z.string().min(1, 'O campo UF é obrigatório!'),
	a1Bairro: z
		.string()
		.min(1, 'O campo Bairro é obrigatório!')
		.max(100, 'O Bairro deve ter no máximo 100 caracteres')
		.transform((value) => removerAcentos(value)),
	a1Bairroc: z
		.string()
		.max(100, 'O Bairro de cobrança deve ter no máximo 100 caracteres')
		.default('')
		.transform((value) => removerAcentos(value)),
	a1Canal: z.string().min(1, 'O campo Canal cliente é obrigatório!'),
	a1Ccusto: z
		.string({ required_error: 'O campo Centro de custo é obrigatório!' })
		.min(1),
	a1Cep: z
		.string()
		.min(9, 'O CEP deve ter 8 dígitos')
		.transform((value) => value?.replace('-', '')),
	a1Cepc: z
		.string()
		.default('')
		.transform((value) => value?.replace('-', '')),
	a1Complem: z
		.string()
		.default('')
		.transform((value) => removerAcentos(value)),
	a1Contato: z
		.string()
		.min(1, 'O campo Contato é obrigatório!')
		.max(60, 'O Contato deve ter no máximo 60 caracteres')
		.transform((value) => removerAcentos(value)),
	a1Contrib: z
		.string({ required_error: 'O campo Contribuinte é obrigatório!' })
		.min(1),
	a1Ddd: z.string().length(2, '**'),
	a1Email: z
		.string()
		.min(1, 'O campo E-mail é obrigatório!')
		.max(250, 'O E-mail deve ter no máximo 250 caracteres')
		.transform((value) => value?.replace(',', ';')),
	a1End: z
		.string()
		.min(1, 'O campo Endereço é obrigatório!')
		.max(100, 'O Endereço deve ter no máximo 100 caracteres')
		.transform((value) => removerAcentos(value)),
	a1Endcob: z
		.string()
		.max(100, 'O Endereço de cobrança deve ter no máximo 100 caracteres')
		.default('')
		.transform((value) => removerAcentos(value)),
	a1Estc: z.string().default(''),
	a1Grppra: z
		.string({
			required_error: 'O campo Grupo cliente é obrigatório!'
		})
		.min(1),
	a1Inscr: z
		.string()
		.min(1, 'O campo IE é obrigatório!')
		.max(18, 'O campo IE deve ter no máximo 18 caracteres'),
	a1Loja: z.string().default('01'),
	a1Munc: z
		.string()
		.max(15, 'O Município de cobrança deve ter no máximo 15 caracteres')
		.default('')
		.transform((value) => removerAcentos(value)),
	a1Naturez: z
		.string()
		.min(1, 'O campo Natureza é obrigatório!')
		.max(100, 'O campo Natureza deve ter no máximo 100 caracteres'),
	a1Nome: z
		.string()
		.min(1, 'O campo Razão social é obrigatório!')
		.max(100, 'A Razão social deve ter no máximo 100 caracteres')
		.transform((value) => removerAcentos(value)),
	a1Nreduz: z
		.string()
		.min(1, 'O campo Nome fantasia é obrigatório!')
		.max(100, 'O Nome fantasia deve ter no máximo 100 caracteres')
		.transform((value) => removerAcentos(value)),
	a1Pessoa: z
		.string({
			required_error: 'O campo Tipo de pessoa é obrigatório!'
		})
		.min(1),
	a1Regiao: z
		.string({
			required_error: 'O campo Região é obrigatório!'
		})
		.min(1),
	a1Suframa: z.string().max(12, 'A Suframa deve ter no máximo 12 caracteres'),
	a1Tecnico: z.string().default('N'),
	a1Tel: z
		.string()
		.min(9, 'O campo Telefone deve ter pelo menos 8 dígitos!')
		.max(10, 'O campo Telefone deve ter no máximo 9 dígitos!'),
	a1Telex: z
		.string()
		.max(100, 'O campo Telefone deve ter no máximo 100 dígitos'),
	a1Tipo: z
		.string({
			required_error: 'O campo Tipo de cliente é obrigatório!'
		})
		.min(1, 'O campo Tipo de cliente é obrigatório!'),
	a1Valie: z
		.string({
			required_error: 'O campo IE Validada é obrigatório!'
		})
		.min(1),
	a1Repres: z.string().default(''),
	a1Ytipocl: z
		.string({
			required_error: 'O campo Segmento é obrigatório!'
		})
		.min(1),
	a1Xblfin: z.string().default('N'),
	a1Pais: z.string().default('105')
})

export type updateClientSchema = z.infer<typeof updateClientSchema>
