import React, { ReactNode, createContext, useEffect } from 'react'

export const InstallAndSearchContext = createContext({})

const routePublic = ['/service', '/installation', '/result', '/result-address']

export const InstallAndSearchProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const { pathname } = window.location

	useEffect(() => {
		if (routePublic.includes(pathname)) {
			const webMessengerContainer = document.getElementById(
				'web-messenger-container'
			)
			const updateNotification = document.getElementById(
				'update-notification-bar'
			)

			if (webMessengerContainer && updateNotification) {
				webMessengerContainer.style.display = 'none'
				updateNotification.style.display = 'none'
			}

			const observer = new MutationObserver((mutationsList) => {
				mutationsList.forEach((mutation) => {
					const addedNodes = Array.from(mutation.addedNodes)
					addedNodes.forEach((node) => {
						if (node instanceof HTMLElement) {
							if (
								node.id === 'update-notification-bar' ||
								node.id === 'web-messenger-container'
							) {
								const updateNotification = document.getElementById(
									'update-notification-bar'
								) as HTMLElement | null
								if (updateNotification) {
									updateNotification.style.display = 'none'
								} // Aqui você pode executar ações quando os elementos são adicionados
								node.remove()
							}
						}
					})
				})
			})

			observer.observe(document.body, { childList: true, subtree: true })

			return () => {
				observer.disconnect() // Certifique-se de desconectar o observador quando o componente é desmontado
			}
		}
	}, [])

	const value = {}

	return (
		<InstallAndSearchContext.Provider value={value}>
			{children}
		</InstallAndSearchContext.Provider>
	)
}
