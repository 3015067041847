/* eslint-disable prettier/prettier */
/* eslint-disable no-extra-boolean-cast */
import jwt from 'jsonwebtoken';
import Smooch from 'smooch';
import { smoochConfigs } from './SmoochConfig';

const handleUserConfig = (user: any): { externalId: string; jwt: string } => {
	const jwtEncrypt = jwt.sign(
		{
			scope: 'user',
			external_id: user.email,
			name: `${user.name} ${user.surname}`
		},
		process.env.REACT_APP_SECRET_SUNCO || '',
		{
			header: {
				alg: 'HS256',
				typ: 'JWT',
				kid: process.env.REACT_APP_KID_SUNCO
			}
		}
	)
	const externalId = user.email
	return { jwt: jwtEncrypt, externalId }
}

const initializeSmooch = async (signed?: string, user?: any) => {
	if (signed) {
		const { externalId, jwt } = handleUserConfig(user);

		await Smooch.destroy();
		return await Smooch.init({
			...smoochConfigs,
			externalId,
			jwt
		});
	} else {
		await Smooch.init({
			...smoochConfigs
		});
	}
};

const SmoochWebChat = async (signed?: string, user?: any) => {
	if (!Smooch.isOpened) {
		await initializeSmooch(signed, user);
	} else {
		if (!signed) {
			await Smooch.destroy();
		} else {
			const smoochUser = Smooch.getUser();
			const { externalId } = handleUserConfig(user);

			if (smoochUser?.externalId !== externalId) {
				await initializeSmooch(signed, user);
			}
		}
	}

	return Smooch;
}

export default SmoochWebChat
