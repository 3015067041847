import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'
import {
	countriesList,
	FederalStatesList
} from 'module/orderManagement/utils/constants'
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import Divider from 'shared/components/Divider'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import { store } from 'store'
import { normalizeCepNumber, normalizeTelNumberWithoutDDD } from 'utils/Pipe'
import './styles.scss'

const sameBillingAddress = [
	{ value: 'S', label: 'Sim' },
	{ value: 'N', label: 'Não' }
]

export const ClientAddressInformationEditingMode = () => {
	const { sameBillingAddresses, onUpdateSameBillingAddress } = useContext(
		ClientDetailsContextElement
	)
	const { countyList } = store.getState().baseLists

	const {
		register,
		setValue,
		getValues,
		formState: { errors },
		watch
	} = useFormContext<updateClientSchema>()

	const watchedA1Est = watch('a1Est')

	const filteredCountyList = countyList.filter(
		(option: any) => option.state === watchedA1Est
	)

	const onSelectSameBillingAddress = (option: any) => {
		if (option[0] === 'N') {
			onUpdateSameBillingAddress(false)
			return
		} else if (option[0] === 'S') {
			onUpdateSameBillingAddress(true)
			return
		}
	}

	const billingAddressEditContain = () => {
		return (
			<>
				<div className="client-address-billing-information">
					<div className="client-address-billing-header">
						<Divider title="Dados do endereço de cobrança" />
					</div>
					<div className="client-address-billing-information-container">
						<div className="client-address-billing-information-contain-information">
							<header>Endereço</header>
							<Input
								name="a1Endcob"
								variant="light"
								errors={errors.a1Endcob}
								innerRef={{ ...register('a1Endcob') }}
							/>
						</div>
						<div className="client-address-billing-information-contain-information">
							<header>Bairro</header>
							<Input
								name="a1Bairroc"
								variant="light"
								errors={errors.a1Bairroc}
								innerRef={{ ...register('a1Bairroc') }}
							/>
						</div>
						<div className="client-address-billing-information-contain-information">
							<header>Município</header>
							<Input
								name="a1Munc"
								variant="light"
								errors={errors.a1Munc}
								innerRef={{ ...register('a1Munc') }}
							/>
						</div>
						<div className="client-address-billing-information-contain-information">
							<header>CEP</header>
							<Input
								name="a1Cepc"
								maxLength={9}
								variant="light"
								errors={errors.a1Cepc}
								innerRef={{ ...register('a1Cepc') }}
								onChange={(e) =>
									setValue('a1Cepc', normalizeCepNumber(e.target.value))
								}
							/>
						</div>
						<div className="client-address-billing-information-contain-information">
							<header>UF</header>
							<Select
								id="a1Estc"
								autoComplete="none"
								errors={errors.a1Estc}
								options={FederalStatesList}
								innerRef={{ ...register('a1Estc') }}
								selectedOption={[getValues('a1Estc')]}
								onSelectOptions={(option) => setValue('a1Estc', option[0])}
							/>
						</div>
						<div className="client-address-billing-information-contain-information"></div>
					</div>
				</div>
			</>
		)
	}

	return (
		<div>
			<div className="client-address-information">
				<div className="client-address-information-contain-information">
					<header>Contato</header>
					<Input
						variant="light"
						name="a1Contato"
						autoComplete="none"
						errors={errors.a1Contato}
						innerRef={{ ...register('a1Contato') }}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>Telefone</header>
					<div className="client-address-information-contact-number">
						<div className="contact-number-ddd">
							<Input
								name="a1Ddd"
								icon={false}
								maxLength={2}
								variant="light"
								errors={errors.a1Ddd}
								innerRef={{ ...register('a1Ddd') }}
							/>
						</div>
						<div className="contact-number">
							<Input
								name="a1Tel"
								minLength={9}
								maxLength={10}
								variant="light"
								autoComplete="none"
								errors={errors.a1Tel}
								innerRef={{ ...register('a1Tel') }}
								onChange={(e) =>
									setValue(
										'a1Tel',
										normalizeTelNumberWithoutDDD(e.target.value)
									)
								}
							/>
						</div>
					</div>
				</div>
				<div className="client-address-information-contain-information">
					<header>Celular</header>
					<div className="client-address-information-cel-number">
						<div className="cel-number">
							<Input
								name="a1Telex"
								variant="light"
								maxLength={100}
								errors={errors.a1Telex}
								innerRef={{ ...register('a1Telex') }}
							/>
						</div>
					</div>
				</div>
				<div className="client-address-information-contain-information">
					<header>E-mail</header>
					<Input
						type="text"
						name="a1Email"
						variant="light"
						autoComplete="none"
						errors={errors.a1Email}
						innerRef={{ ...register('a1Email') }}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>Endereço</header>
					<Input
						name="a1End"
						variant="light"
						autoComplete="none"
						errors={errors.a1End}
						innerRef={{ ...register('a1End') }}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>Bairro</header>
					<Input
						name="a1Bairro"
						variant="light"
						autoComplete="none"
						errors={errors.a1Bairro}
						innerRef={{ ...register('a1Bairro') }}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>UF</header>
					<Select
						id="a1Est"
						autoComplete="none"
						errors={errors.a1Est}
						options={FederalStatesList}
						innerRef={{ ...register('a1Est') }}
						selectedOption={[getValues('a1Est')]}
						onSelectOptions={(option) => setValue('a1Est', option[0])}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>CEP</header>
					<Input
						name="a1Cep"
						maxLength={9}
						variant="light"
						autoComplete="none"
						errors={errors.a1Cep}
						innerRef={{ ...register('a1Cep') }}
						onChange={(e) =>
							setValue('a1Cep', normalizeCepNumber(e.target.value))
						}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>Município</header>
					<Select
						id="a1CodMun"
						autoComplete="off"
						errors={errors.a1CodMun}
						options={filteredCountyList}
						innerRef={{ ...register('a1CodMun') }}
						selectedOption={[getValues('a1CodMun')]}
						onSelectOptions={(option) => setValue('a1CodMun', option[0])}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>País</header>
					<Select
						id="a1Pais"
						autoComplete="off"
						errors={errors.a1Pais}
						options={countriesList}
						innerRef={{ ...register('a1Pais') }}
						selectedOption={[getValues('a1Pais') || '']}
						onSelectOptions={(option) => setValue('a1Pais', option[0])}
					/>
				</div>
				<div className="client-address-information-contain-information">
					<header>Endereço de cobrança é o mesmo?</header>
					<Select
						id="same_billing_select"
						options={sameBillingAddress}
						onSelectOptions={(option) => {
							setValue('sameBillingAddress', option[0] === 'S')
							onSelectSameBillingAddress(option[0])
						}}
						innerRef={{ ...register('sameBillingAddress') }}
						selectedOption={[sameBillingAddresses ? 'S' : 'N']}
					/>
				</div>
			</div>
			<div>{!sameBillingAddresses && billingAddressEditContain()}</div>
		</div>
	)
}
