import { zodResolver } from '@hookform/resolvers/zod'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { ClientEditingModeComposition } from 'module/orderManagement/components/ClientEditingModeComposition'
import { ClientViewModeComposition } from 'module/orderManagement/components/ClientViewModeComposition'
import OrderMenuTabs from 'module/orderManagement/components/OrderMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { MdEdit } from 'react-icons/md'
import { RouteComponentProps } from 'react-router-dom'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import { ViewType } from 'utils/Constants'
import { normalizeCGC, normalizeCepNumber } from 'utils/Pipe'
import './styles.scss'

type ClientDetailsProps = RouteComponentProps & {
	match: {
		params: {
			clientCode: string
			clientStoreCode: string
		}
	}
}

export default function OrderClientDetails({ match }: ClientDetailsProps) {
	const { viewType } = useContext(DeviceContextElement)
	const {
		sameBillingAddresses,
		sa1010,
		sa1010IsLoading,
		onSelectClient,
		onUpdateClient
	} = useContext(ClientDetailsContextElement)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [clientEditInfo, setClientEditInfo] = useState(sa1010)
	const { clientCode, clientStoreCode } = match.params
	const methods = useForm<updateClientSchema>({
		resolver: zodResolver(updateClientSchema)
	})

	useEffect(() => {
		if (sa1010?.a1Cod !== clientCode) {
			onSelectClient({ a1Cod: clientCode, a1Loja: clientStoreCode })
		}

		if (sa1010) {
			setClientEditInfo(sa1010)
		}

		methods.reset({
			a1Loja: clientEditInfo?.a1Loja,
			a1Tecnico: clientEditInfo?.a1Tecnico,
			a1Nome: clientEditInfo?.a1Nome,
			a1Nreduz: clientEditInfo?.a1Nreduz,
			a1Pessoa: clientEditInfo?.a1Pessoa,
			a1Cgc: normalizeCGC(clientEditInfo?.a1Cgc),
			a1Inscr: clientEditInfo?.a1Inscr,
			a1Valie: clientEditInfo?.a1Valie,
			a1Regiao: clientEditInfo?.a1Regiao,
			a1Ytipocl: clientEditInfo?.a1Ytipocl,
			a1Tipo: clientEditInfo?.a1Tipo,
			a1Canal: clientEditInfo?.a1Canal,
			a1Classif: clientEditInfo?.a1Classif,
			a1Grppra: clientEditInfo?.a1Grppra,
			a1Suframa: clientEditInfo?.a1Suframa,
			a1Repres: clientEditInfo?.a1Repres,
			a1Ddd: clientEditInfo?.a1Ddd,
			a1Tel: clientEditInfo?.a1Tel,
			a1Email: clientEditInfo?.a1Email,
			a1Contato: clientEditInfo?.a1Contato,
			a1Telex: clientEditInfo?.a1Telex,
			a1End: clientEditInfo?.a1End,
			a1CodMun: clientEditInfo?.a1CodMun,
			a1Bairro: clientEditInfo?.a1Bairro,
			a1Complem: clientEditInfo?.a1Complem,
			a1Est: clientEditInfo?.a1Est,
			a1Cep: normalizeCepNumber(clientEditInfo?.a1Cep),
			a1Naturez: clientEditInfo?.a1Naturez,
			a1Conta: clientEditInfo?.a1Conta,
			a1Ccusto: clientEditInfo?.a1Ccusto,
			a1Contrib: clientEditInfo?.a1Contrib,
			a1Endcob: clientEditInfo?.a1Endcob,
			a1Xblfin: clientEditInfo?.a1Xblfin,
			a1Pais: clientEditInfo?.a1Pais,
			a1Munc: clientEditInfo?.a1Munc,
			a1Bairroc: clientEditInfo?.a1Bairroc,
			a1Estc: clientEditInfo?.a1Estc,
			a1Cepc: normalizeCepNumber(clientEditInfo?.a1Cepc)
		})
	}, [clientCode, clientStoreCode, sa1010])

	useEffect(() => {
		if (clientEditInfo) {
			if (sameBillingAddresses) {
				methods.setValue('a1Endcob', clientEditInfo.a1End)
				methods.setValue('a1Munc', clientEditInfo.a1CodMun)
				methods.setValue('a1Bairroc', clientEditInfo.a1Bairro)
				methods.setValue('a1Estc', clientEditInfo.a1Est)
				methods.setValue('a1Cepc', normalizeCepNumber(clientEditInfo?.a1Cep))
			} else {
				methods.setValue('a1Endcob', clientEditInfo.a1Endcob)
				methods.setValue('a1Munc', clientEditInfo.a1Munc)
				methods.setValue('a1Bairroc', clientEditInfo.a1Bairroc)
				methods.setValue('a1Estc', clientEditInfo.a1Estc)
				methods.setValue('a1Cepc', normalizeCepNumber(clientEditInfo?.a1Cepc))
			}
		}
	}, [sameBillingAddresses, clientEditInfo])

	const onSubmit = (data: updateClientSchema) => {
		if (sa1010) {
			setClientEditInfo({
				...sa1010,
				...data
			})
			onUpdateClient({ a1Cod: clientCode, a1Loja: clientStoreCode }, data, () =>
				setIsEditing(false)
			)
		}
	}

	const ViewingModeContent = () => {
		return <ClientViewModeComposition />
	}

	const EditingModeContent = () => {
		return (
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit(onSubmit)}
					autoComplete="off"
					autoCapitalize="off"
				>
					<ClientEditingModeComposition />
					<div
						style={{
							marginTop: '20px',
							width: '100%',
							display: 'grid',
							gridTemplateColumns: '128px 128px',
							justifyContent: 'center',
							gap: '20px'
						}}
					>
						<Button
							size="lg"
							title="CONFIRMAR"
							variant="confirmation-gradient"
							disabled={sa1010IsLoading}
							type="submit"
						/>
						<Button
							variant="cancellation-gradient"
							title="CANCELAR"
							size="lg"
							disabled={sa1010IsLoading}
							onClick={() => {
								setIsEditing(false)
							}}
						/>
					</div>
				</form>
			</FormProvider>
		)
	}

	return (
		<>
			<OrderMenuTabs path={match.path} />
			<TabTitles />
			<div className="order-client-tab">
				<div className="section-container-action-edit">
					<Button
						size="lg"
						title={
							<span className="edit-button">
								{viewType === ViewType.MOBILE && <MdEdit size={28} />}
								{!(viewType === ViewType.MOBILE) && 'Editar'}
							</span>
						}
						variant="confirmation-solid"
						onClick={() => setIsEditing(true)}
						disabled={isEditing || sa1010IsLoading}
					/>
				</div>
				{isEditing && !sa1010IsLoading ? (
					<EditingModeContent />
				) : (
					<ViewingModeContent />
				)}
			</div>
			<LoadingProgress
				show={sa1010IsLoading}
				text="Carregando dados do cliente..."
			/>
		</>
	)
}
