import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { getCountry } from 'module/orderManagement/utils/constants'
import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import Divider from 'shared/components/Divider'
import './styles.scss'

export const ClientAddressInformationViewingMode = () => {
	const { sa1010, sa1010IsLoading, sameBillingAddresses } = useContext(
		ClientDetailsContextElement
	)

	const billingAddressContain = () => {
		return (
			<div className="client-address-billing-information">
				<div className="client-address-billing-header">
					<Divider title="Dados do endereço de cobrança" />
				</div>
				<div className="client-address-billing-information-container">
					<div className="client-address-billing-information-contain-information">
						<header>Endereço</header>
						<span>
							{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Endcob ?? '-'}
						</span>
					</div>
					<div className="client-address-billing-information-contain-information">
						<header>Bairro</header>
						<span>
							{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Bairroc ?? '-'}
						</span>
					</div>
					<div className="client-address-billing-information-contain-information">
						<header>Município</header>
						<span>
							{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Munc ?? '-'}
						</span>
					</div>
					<div className="client-address-billing-information-contain-information">
						<header>CEP</header>
						<span>
							{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Cepc ?? '-'}
						</span>
					</div>
					<div className="client-address-billing-information-contain-information">
						<header>UF</header>
						<span>
							{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Estc ?? '-'}
						</span>
					</div>
					<div className="client-address-billing-information-contain-information"></div>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="client-address-information">
				<div className="client-address-information-contain-information">
					<header>Contato</header>
					<span>
						{sa1010IsLoading ? (
							<Skeleton />
						) : sa1010?.a1Contato ? (
							sa1010?.a1Contato
						) : (
							'-'
						)}
					</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>Telefone</header>
					<span>
						{sa1010IsLoading ? (
							<Skeleton />
						) : !!sa1010?.a1Ddd && !!sa1010?.a1Tel ? (
							`(${sa1010?.a1Ddd}) ${sa1010?.a1Tel}`
						) : (
							'-'
						)}
					</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>Celular</header>
					<span>
						{sa1010IsLoading ? (
							<Skeleton />
						) : sa1010?.a1Telex ? (
							`${sa1010?.a1Telex}`
						) : (
							'-'
						)}
					</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>E-mail</header>
					<span>{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Email ?? '-'}</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>Endereço</header>
					<span>{sa1010IsLoading ? <Skeleton /> : sa1010?.a1End ?? '-'}</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>Bairro</header>
					<span>
						{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Bairro ?? '-'}
					</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>UF</header>
					<span>{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Est ?? '-'}</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>CEP</header>
					<span>{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Cep ?? '-'}</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>Município</header>
					<span>
						{sa1010IsLoading ? <Skeleton /> : sa1010?.cc2010?.cc2Mun ?? '-'}
					</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>País</header>
					<span>
						{sa1010IsLoading ? (
							<Skeleton />
						) : (
							getCountry(`${sa1010?.a1Pais}`)?.label || '-'
						)}
					</span>
				</div>
				<div className="client-address-information-contain-information">
					<header>Endereço de cobrança é o mesmo?</header>
					<>{sameBillingAddresses ? <span>Sim</span> : <span>Não</span>}</>
				</div>
			</div>
			<div>
				{!sameBillingAddresses && sa1010?.a1Endcob && billingAddressContain()}
			</div>
		</>
	)
}
