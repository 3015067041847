import AllCards from 'module/recognitionCard/components/AllCards/AllCards'
import { NewCard } from 'module/recognitionCard/components/Cards/NewCard'
import { RecognitionCardContext } from 'module/recognitionCard/context/recognitionCardContext'
import React, { useContext, useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import RecognitionCardIcon from './RecognitionCardIcon'
import './styles.scss'

interface RecognitionCardProps {
	employeesList: any
}

const RecognitionCard: React.FC<RecognitionCardProps> = ({ employeesList }) => {
	const [showNewCardModal, setShowNewCardModal] = useState<boolean>(false)
	const [showModalAllCards, setShowModalAllCards] = useState<boolean>(false)
	const { onGetQuantCards, quantCards, isLoading } = useContext(
		RecognitionCardContext
	)

	useEffect(() => {
		onGetQuantCards()
	}, [])

	return (
		<>
			<div className="feature-card" style={{ cursor: 'default' }}>
				<div className="recognition-card-title">
					<h4>CARTÃO DE RECONHECIMENTO</h4>
					<div className="recognition-card-title-icon">
						<RecognitionCardIcon />
					</div>
				</div>
				<div className="recognition-card-main-contain">
					<div className="text-card-container">
						<p className="subtext">
							O reconhecimento do trabalho bem feito é o melhor combustível para
							se manter em movimento!
						</p>
					</div>
					<p className="msgCard">
						{quantCards > 0
							? `Você possui ${quantCards} cartões para enviar esse mês!`
							: 'Você não possui mais cartões para enviar esse mês!'}
					</p>
				</div>
				<div className="recognition-card-actions">
					<Button
						title="Meus Cartões"
						variant="confirmation-solid"
						size="md"
						onClick={() => setShowModalAllCards(true)}
					/>
					<Button
						title="Enviar Cartão"
						size="md"
						variant="confirmation-solid"
						onClick={() => {
							if (quantCards > 0) {
								setShowNewCardModal(true)
							} else {
								setShowModalAllCards(true)
							}
						}}
					/>
				</div>
			</div>
			{isLoading && <LoadingProgress show={isLoading} />}
			{showModalAllCards && (
				<AllCards onClose={() => setShowModalAllCards(false)} />
			)}
			{showNewCardModal && (
				<NewCard
					employeesList={employeesList}
					onClose={() => setShowNewCardModal(false)}
				/>
			)}
		</>
	)
}

export default RecognitionCard
