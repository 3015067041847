import HumanResourcesIcon from 'module/entryPoint/pages/FeaturesHome/humanResourcesCard/HumanResourcesCardIcon'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { history } from 'routes/history'
import Button from 'shared/components/Button'
import { updateModalStatus } from 'store/modules/user/actions'
import './styles.scss'

interface Props {
	employeesList: any
}

const HumanResourcesCardIcon: React.FC<Props> = ({ employeesList }) => {
	const dispatch = useDispatch()
	const [countUser, setCountUser] = useState<number>(0)
	const [countUserWeek, setCountUserWeek] = useState<number>(0)

	useEffect(() => {
		dispatch(updateModalStatus(false))

		setCountUser(employeesList?.length || 0)

		let contagem = 0
		employeesList?.forEach((data: any) => {
			const date = new Date()
			date.setDate(date.getDate() - 7)
			const week = date.toISOString()
			if (data.created_at >= week) {
				contagem = contagem + 1
			}
		})
		setCountUserWeek(contagem)
	}, [employeesList])

	const handleRegisterCollaborator = () => {
		dispatch(updateModalStatus(true))
		history.push('/human_resources/collaborators')
	}

	return (
		<div
			className="feature-card"
			onClick={() => {
				history.push(`/human_resources/collaborators`)
			}}
		>
			<div className="human-resources-card-title">
				<h4>RECURSOS HUMANOS</h4>
				<div className="human-resources-config-icon">
					<HumanResourcesIcon />
				</div>
			</div>
			<div className="human-resources-card-main-contain">
				<div>
					<p className="subtext">Resumo da semana: </p>
					<div className="info-card">
						{countUserWeek} colabores cadastrados.
					</div>
					<div className="info-card">
						{countUser} usuários ativos na plataforma.
					</div>
				</div>
			</div>
			<div className="human-resources-card-actions">
				<Button
					title="Cadastrar Colaborador"
					variant="confirmation-solid"
					size="md"
					onClick={() => {
						handleRegisterCollaborator()
					}}
				/>
			</div>
		</div>
	)
}

export default HumanResourcesCardIcon
