export const smoochConfigs: InitOptions = {
	integrationId: '61aa78f7c087a800ecb462ce',

	customColors: {
		brandColor: '004687',
		conversationColor: '025CB7',
		actionColor: '5DAEFF'
	},

	buttonWidth: '40',
	buttonHeight: '40',

	displayStyle: 'button',
	buttonIconUrl:
		'https://praticasharedfiles.s3.sa-east-1.amazonaws.com/images/Pratica+chat+icon+(1).png',

	locale: 'pt-BR',

	fixedHeader: true,

	embedded: false,

	businessName: 'PRP - Digital Endeavors',

	businessIconUrl:
		'https://praticasharedfiles.s3.sa-east-1.amazonaws.com/images/Pratica+chat+icon+(1).png',

	canUserSeeConversationList: true,

	customText: {
		connectNotificationText:
			'Você pode continuar sua conversa conosco a qualquer momento em seu canal de mensagens favorito, sincronize aqui :)',
		inputPlaceholder: 'Digite uma mensagem...',
		clickToRetry: 'Mensagem não enviada. Clique para reenviar.',
		clickToRetryForm:
			'Formulário não enviado. Clique em qualquer lugar no formulário para reenviar.',
		connectNotificationSingleText:
			'Seja notificado(a) quando receber uma resposta.',
		conversationListHeaderText: 'Minhas conversas',
		conversationListRelativeTimeJustNow: 'Agora',
		conversationListRelativeTimeMinute: '1 minuto atrás',
		conversationListRelativeTimeMinutes: '{value} minutos atrás',
		conversationListRelativeTimeHour: '1 hora atrás',
		conversationListRelativeTimeHours: '{value} horas atrás',
		conversationListRelativeTimeYesterday: 'Ontem',
		conversationListTimestampFormat: 'DD/MM/YY',
		conversationListPreviewAnonymousText: 'Alguém',
		conversationListPreviewCarouselText: '{user} enviou uma mensagem',
		conversationListPreviewFileText: '{user} enviou um arquivo',
		conversationListPreviewFormText: '{user} enviou um formulário',
		conversationListPreviewFormResponseText: '{user} preenchia um formulário',
		conversationListPreviewImageText: '{user} enviou uma imagem',
		conversationListPreviewLocationRequestText:
			'{user} enviou um pedido de local',
		conversationListPreviewUserText: 'Você',
		conversationTimestampHeaderFormat: 'D MMMM YYYY, h:mm A',
		couldNotConnect: 'Off-line :(  Você não receberá mensagens.',
		couldNotConnectRetry: 'Reconectando ...',
		couldNotConnectRetrySuccess: 'Você está de volta online!',
		couldNotLoadConversations: 'Não podia carregar conversas.',
		emailChangeAddress: 'Mude meu e-mail',
		emailDescription:
			'Para ser notificado por e-mail quando você receber uma resposta, insira seu endereço de e-mail.',
		emailFieldLabel: 'E-mail',
		emailFieldPlaceholder: 'Seu endereço de e-mail',
		emailFormButton: 'Enviar',
		emailLinkingErrorMessage: 'Por favor, envie um endereço de e-mail válido.',
		fetchHistory: 'Carregue mais',
		fetchingHistory: 'Recuperando a história ...',
		fileTooLargeError: 'Limite de tamanho máximo de arquivo excedido ({size})',
		fileTypeError: 'Tipo de arquivo não suportado.',
		formErrorInvalidEmail: 'E-mail inválido',
		formErrorNoLongerThan:
			'Deve conter não mais do que ({characters}) caracteres',
		formErrorNoShorterThan: 'Deve conter pelo menos ({characters}) caracteres',
		formErrorUnknown: 'Isso não parece certo',
		formFieldSelectPlaceholderFallback: 'Escolha um...',
		frontendEmailChannelDescription:
			'Para falar conosco usando o e-mail, basta enviar uma mensagem para nosso endereço de e-mail e responderemos em breve:',
		headerText: 'Como podemos ajudar?',
		imageClickToReload: 'Clique para recarregar a imagem.',
		imageClickToView: 'Clique para ver {size} Imagem.',
		imagePreviewNotAvailable: 'Pré-visualização não disponível.',
		inputPlaceholderBlocked: 'Preencha o formulário acima ...',
		introAppText: 'Mencione-nos abaixo ou do seu aplicativo favorito.',
		lineChannelDescription:
			'Para falar conosco usando a linha, digitalize este código QR usando o aplicativo Line e envie-nos uma mensagem.',
		linkError:
			'Ocorreu um erro ao tentar gerar um link para este canal. Por favor, tente novamente.',
		linkChannelPageHeader: 'Sincronize sua conversa',
		locationNotSupported:
			'Seu navegador não suporta serviços de localização ou foi desativado. Por favor, digite sua localização em vez disso.',
		locationSecurityRestriction:
			'Este site não pode acessar sua localização. Por favor, digite sua localização em vez disso.',
		locationSendingFailed: 'Não foi possível enviar a localização',
		locationServicesDenied:
			'Este site não pode acessar sua localização. Permitir acesso em suas configurações ou digite sua localização.',
		messageError:
			'Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente.',
		messageIndicatorTitlePlural: '({count}) novas mensagens',
		messageIndicatorTitleSingular: '({count}) nova mensagem',
		messageRelativeTimeDay: '{value}d atrás',
		messageRelativeTimeHour: '{value}h atrás',
		messageRelativeTimeJustNow: 'Agora mesmo',
		messageRelativeTimeMinute: '{value}m atrás',
		messageTimestampFormat: 'h:mm A',
		messageDelivered: 'Entregue',
		messageSeen: 'Visto',
		messageSending: 'Enviando ...',
		messageTooLongError:
			'Limite de tamanho máximo de mensagem excedido ({size}).',
		messengerChannelDescription:
			'Conecte sua conta do Facebook Messenger para ser notificado(a) quando você receber uma resposta e continuar a conversa no Facebook Messenger.',
		newConversationButtonText: 'Nova conversa',
		notificationSettingsChannelsDescription:
			'Sincronize esta conversa, conectando-se ao seu aplicativo de mensagens favorito para continuar a conversa do seu jeito.',
		notificationSettingsChannelsTitle: 'Outros canais',
		notificationSettingsConnected: 'Conectado',
		notificationSettingsConnectedAs: 'Conectado como {username}',
		prechatCaptureGreetingText:
			'Olá 👋  NPara começar, gostaríamos de saber um pouco mais sobre você:',
		prechatCaptureNameLabel: 'Seu nome',
		prechatCaptureNamePlaceholder: 'Digite seu nome...',
		prechatCaptureEmailLabel: 'E-mail',
		prechatCaptureEmailPlaceholder: 'name@company.com.',
		prechatCaptureConfirmationText:
			'Obrigado por isso! Em que podemos ajudá-lo?',
		prechatCaptureMailgunLinkingConfirmation:
			'Você será notificado aqui e por e-mail no {e-mail} uma vez que respondermos.',
		sendButtonText: 'Mandar',
		settingsHeaderText: 'Configurações',
		shareLocation: 'Localização',
		smsBadRequestError:
			'Não conseguimos comunicar com esse número. Tente novamente ou use um diferente.',
		smsCancel: 'Cancelar',
		smsChangeNumber: 'Mude meu número',
		smsChannelDescription:
			'Conecte seu número SMS para ser notificado quando você receber uma resposta e continuar a conversa via SMS.',
		smsChannelPendingDescription:
			'Verifique suas mensagens no {number} para confirmar seu número de telefone.',
		smsContinue: 'Enviar',
		smsInvalidNumberError: 'Por favor, envie um número de telefone válido.',
		smsLinkCancelled: 'Link para {appUserNumber} foi cancelado.',
		smsLinkPending: 'Pendente',
		smsPingChannelError:
			'Houve um erro ao enviar uma mensagem para o seu número.',
		smsSendText: 'Me envie um texto',
		smsStartTexting: 'Comece digitando',
		smsTooManyRequestsError:
			'Uma conexão para esse número foi solicitada recentemente. Por favor, tente novamente em {minutes} minutos.',
		smsTooManyRequestsOneMinuteError:
			'Uma conexão para esse número foi solicitada recentemente. Por favor, tente novamente em 1 minuto.',
		smsUnhandledError: 'Algo deu errado. Por favor, tente novamente.',
		syncConversation: 'Sincronize essa conversa',
		tapToRetry: 'Mensagem não enviada. Toque para tentar novamente.',
		tapToRetryForm:
			'Formulário não submetida. Toque em qualquer lugar do formulário para tentar novamente.',
		telegramChannelDescription:
			'Conecte sua conta de Telegram para ser notificado(a) quando você receber uma resposta e continuar a conversa no Telegram.',
		unsupportedMessageType: 'Tipo de mensagem não suportado.',
		unsupportedActionType: 'Tipo de ação não suportado.',
		uploadDocument: 'Arquivo',
		uploadInvalidError: 'Arquivo inválido.',
		uploadPhoto: 'Imagem',
		uploadVirusError: 'Um vírus foi detectado em seu arquivo e foi rejeitado',
		viberChannelDescription:
			'Conecte sua conta Viber para ser notificado(a) quando você receber uma resposta e continuar a conversa no Viber. Para começar, digitalize o código QR usando o aplicativo Viber.',
		viberChannelDescriptionMobile:
			'Conecte sua conta Viber para ser notificado(a) quando você receber uma resposta e continuar a conversa no Viber. Para começar, instale o aplicativo Viber e toque em Conectar.',
		viberQRCodeError:
			'Ocorreu um erro ao buscar seu código QR do Viber. Por favor, tente novamente.',
		wechatChannelDescription:
			'Conecte sua conta Wechat para ser notificado(a) quando você receber uma resposta e continuar a conversa no Wechat. Para começar, digitalize este código QR usando o aplicativo Wechat.',
		wechatChannelDescriptionMobile:
			"Conecte sua conta Wechat para ser notificado(a) quando você receber uma resposta e continuar a conversa no Wechat. Para começar, salve essa imagem de código QR e faça o upload <a href='weixin://dl/scan'>QR code scanner</a>.",
		wechatQRCodeError:
			'Ocorreu um erro ao buscar seu código QR Wechat. Por favor, tente novamente.',
		whatsappChannelDescriptionDesktop:
			'Sincronize sua conta para o WhatsApp escaneando o código QR ou clicando no link abaixo.\nEntão, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Seu código: {{code}}). Atenção: A mensagem no dispositivo poderá estar em inglês, basta responder com: yes',
		whatsappChannelDescriptionMobile:
			'Sincronize sua conta no WhatsApp clicando no link abaixo.\nEntão, envie a mensagem pré-preenchida para validar a solicitação de sincronização. (Seu código: {{code}}). Atenção: A mensagem no dispositivo poderá estar em inglês, basta responder com: yes',
		whatsappLinkingError:
			'Ocorreu um erro ao buscar suas informações de vinculação do Whatsapp. Por favor, tente novamente.'
	}
}
