import { RecognitionCardForm } from 'models/RecognitionCard'
import { RecognitionCardContext } from 'module/recognitionCard/context/recognitionCardContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Button from 'shared/components/Button'
import CheckBox from 'shared/components/CheckBox'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import Select from 'shared/components/Select'
import { Textarea } from 'shared/components/Textarea'
import { guidelines } from '../../../constants'
import { Card } from '../Card'
import './style.scss'

interface NewCardProps {
	onClose: () => void
	employeesList: any[]
}

export const NewCard = ({ onClose, employeesList }: NewCardProps) => {
	const [selectedGuideline, setSelectedGuideline] = useState<any[]>([] as any)
	const [guidelineError, setGuidelineError] = useState<boolean>(false)
	const user = useSelector((state: any) => state.user)
	const {
		isLoading,
		dataCard,
		targetRef,
		onCreateNewCard,
		onSendEmail,
		onCleanDataCard
	} = useContext(RecognitionCardContext)
	const dataCardIsEmpty = Object.keys(dataCard).length === 0

	useEffect(() => {
		if (!dataCardIsEmpty) {
			onSendEmail(onClose)
		}
	}, [dataCard])

	useEffect(() => {
		if (!dataCardIsEmpty) {
			onCleanDataCard()
		}
	}, [onSendEmail])

	const employeesAll = employeesList
		.filter((employee) => employee.id !== user.profile)
		.map((employee) => {
			return {
				value: String(employee.id),
				label: employee.name
			}
		})

	const {
		handleSubmit,
		setValue,
		register,
		getValues,
		watch,
		formState: { errors }
	} = useForm<RecognitionCardForm>({
		defaultValues: {
			from_user: user.profile,
			perfection: 'N',
			knowledge: 'N',
			human: 'N',
			harmony: 'N',
			constancy: 'N',
			client: 'N',
			pro_solutions: 'N',
			protagonism: 'N',
			urgency: 'N',
			evolution: 'N'
		}
	})

	const handleSelectItems = (item: any) => {
		const isDisabled =
			selectedGuideline.length >= 2 &&
			!selectedGuideline.some(
				(selectedItem) =>
					selectedItem.name === item.name && selectedItem.label === item.label
			)

		if (isDisabled) return

		const currentIndex = selectedGuideline?.findIndex(
			(guideline) =>
				guideline.name === item?.name && guideline?.label === item?.label
		)
		const newChecked = [...selectedGuideline]
		if (currentIndex === -1) {
			newChecked.push(item)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		setSelectedGuideline(newChecked)
	}

	const onSubmit = (data: RecognitionCardForm) => {
		if (selectedGuideline.length === 0) return setGuidelineError(true)
		setGuidelineError(false)
		onCreateNewCard(data)
	}

	return (
		<>
			<Modal
				show
				title="CARTÃO DE RECONHECIMENTO"
				onClose={() => onClose()}
				showClose
			>
				<div className="new-recognition-card">
					<div className="new-recognition-card-header">
						<label>
							Você pode reconhecer um colega por se encaixar nas diretrizes de
							nossa Qualidade Sem Lacunas!
						</label>
					</div>
					<div className="new-recognition-card-select-employee">
						<label>Qual colega você gostaria de reconhecer?</label>
						<Select
							id="to_user"
							options={employeesAll}
							errors={errors.to_user}
							onSelectOptions={(employee: any) =>
								setValue('to_user', employee[0])
							}
							selectedOption={[String(getValues('to_user'))]}
							innerRef={{
								...register('to_user', {
									required: { value: true, message: '** Campo obrigatório' }
								})
							}}
						/>
					</div>
					<div className="new-recognition-card-select-guidelines">
						<label>
							Selecione até 2 diretrizes da Qualidade Sem Lacunas para atribuir
							a seu colega:
						</label>
						<div className="new-recognition-card-select-guidelines-options">
							{guidelines.map((guideline, index) => (
								<div className="guideline-option" key={index}>
									<CheckBox
										format="square"
										variant={selectedGuideline.length < 2 ? 'blue' : 'gray'}
										checked={selectedGuideline.some(
											(item) =>
												item.name === guideline.name &&
												item.label === guideline.label
										)}
										{...register(guideline.name)}
										onChange={(e) => {
											handleSelectItems(guideline)
											setValue(guideline.name, e.target.checked ? 'S' : 'N')
										}}
										disabled={
											selectedGuideline.length >= 2 &&
											!selectedGuideline.some(
												(item) =>
													item.name === guideline.name &&
													item.label === guideline.label
											)
										}
									/>
									<label>{guideline.label}</label>
								</div>
							))}
						</div>
						{guidelineError && (
							<span className="new-recognition-card-select-guidelines-error">
								** Campo obrigatório
							</span>
						)}
					</div>
					<div className="new-recognition-card-description">
						<Textarea>
							<Textarea.Label
								title="Como tais ações impactaram positivamente nos resultados?"
								htmlFor="obs1"
							/>
							<Textarea.Field
								rows={3}
								id="obs1"
								placeholder="Descreva brevemente as ações realizadas por seu colega"
								{...register('obs1', {
									required: {
										value: true,
										message: 'Campo obrigatório'
									},
									maxLength: 240
								})}
								onChange={(e) => setValue('obs1', e.target.value)}
								maxLength={240}
							/>
							<Textarea.Error
								hasError={!!errors.obs1}
								errorMessage={errors.obs1?.message}
							/>
						</Textarea>
						<label className="new-recognition-card-description-count-characters">
							{240 - (watch('obs1')?.length | 0)} caracteres
						</label>
					</div>
					<div className="new-recognition-card-description">
						<Textarea>
							<Textarea.Label
								title="Como tais ações impactaram positivamente nos resultados?"
								htmlFor="obs2"
							/>
							<Textarea.Field
								id="obs2"
								placeholder="Descreva brevemente quais foram os impactos positivos"
								{...register('obs2', {
									required: {
										value: true,
										message: 'Campo obrigatório'
									},
									maxLength: 120
								})}
								onChange={(e) => setValue('obs2', e.target.value)}
								maxLength={120}
							/>
							<Textarea.Error
								hasError={!!errors.obs2}
								errorMessage={errors.obs2?.message}
							/>
						</Textarea>
						<label className="new-recognition-card-description-count-characters">
							{120 - (watch('obs2')?.length | 0)} caracteres
						</label>
					</div>
					<div className="new-recognition-card-actions">
						<Button
							size="lg"
							title="Enviar"
							variant="confirmation-gradient"
							onClick={() => {
								selectedGuideline.length === 0
									? setGuidelineError(true)
									: setGuidelineError(false)
								handleSubmit(onSubmit)()
							}}
						/>
						<Button
							size="lg"
							title="Cancelar"
							variant="cancellation-gradient"
							onClick={onClose}
						/>
					</div>
				</div>
			</Modal>
			<div className="card-component-render">
				<Card dataCard={dataCard} targetRef={targetRef} />
			</div>
			{isLoading && <LoadingProgress show={isLoading} />}
		</>
	)
}
