import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getEquipmentsBySerial, getTypes, registerTypeOnPrp } from 'services'
import Select from 'shared/components/Select'
import { store } from 'store'

interface Props {
	serialNumber: string
	selectedType: string
	innerRef?: any
	onClick?: (element: string) => void
	onSelectType: (type: string) => void
}

const OptionsTypeOs: React.FC<Props> = ({
	serialNumber,
	onSelectType,
	selectedType,
	innerRef
}) => {
	moment.tz.setDefault('UTC')
	const [preventiveIsActive, setPreventiveIsActive] = useState<boolean>(false)
	const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false)
	const [outWarranty, setOutWarranty] = useState<boolean>(false)
	const rolesUser = store.getState().user.roles

	useEffect(() => {
		if (serialNumber)
			getEquipmentsBySerial(serialNumber)
				.then((resp) => {
					const preventive = resp.data.equipment.preventive || 0
					const dateEndWarranty = resp.data.equipment.date_end_warranty
					setPreventiveIsActive(preventive)

					// Check if the equipment has an active warranty period
					if (dateEndWarranty) {
						const date = moment(dateEndWarranty).toDate()
						const currentDate = new Date()
						setOutWarranty(currentDate > date)
					}
				})
				.catch((error) => {
					console.log(error)
				})

		// Check if the user is Admin
		if (
			rolesUser.includes('digitalEndeavourManager') ||
			rolesUser.includes('fieldServiceManager')
		) {
			setUserIsAdmin(true)
		}
	}, [])

	// registered Service Orders
	let TypeOs = [
		{ value: '{"value": "", "key": ""}', label: '' },
		{
			value: '{"value": "ATENDIMENTO GERAL CONCESSIONARIA", "key": "79"}',
			label: 'Atendimento geral concessionária'
		},
		{
			value: '{"value": "ATENDIMENTO CONCESSIONARIA DEMO", "key": "21"}',
			label: 'Solicitação de atendimento concessionária demo'
		},
		{
			value: '{"value": "ATENDIMENTO CONCESSIONARIA TESTE", "key": "61"}',
			label: 'Solicitação de atendimento concessionária teste'
		},
		{
			value: '{"value": "ATENDIMENTO DE URGENCIA", "key": "40"}',
			label: 'Atendimento de urgência/canal chef'
		},
		{
			value: '{"value": "ATENDIMENTO EPP DEMONSTRACAO", "key": "50"}',
			label: 'Solicitação de atendimento epp demonstração'
		},
		{
			value: '{"value": "CORRETIVA PREVENTIVA", "key": "15"}',
			label: 'Corretiva preventiva'
		},
		{
			value: '{"value": "CORTESIA 1 MES", "key": "11"}',
			label: 'Cortesia um mês'
		},
		{
			value: '{"value": "CORTESIA COMERCIAL", "key": "03"}',
			label: 'Cortesia comercial'
		},
		{
			value: '{"value": "CORTESIA SERVICO TECNICO", "key": "04"}',
			label: 'Cortesia serviço técnico'
		},
		{
			value: '{"value": "DEMONSTRACAO OU TESTE PARA FILIAIS", "key": "78"}',
			label: 'Demonstração ou teste para filiais'
		},
		{
			value: '{"value": "ERRO ADMINISTRATIVO", "key": "14"}',
			label: 'Erro administrativo'
		},
		{
			value: '{"value": "FORA DE GARANTIA", "key": "06"}',
			label: 'Fora de garantia'
		},
		{
			value: '{"value": "GARANTIA ESTENDIDA", "key": "10"}',
			label: 'Garantia estendida'
		},
		{
			value: '{"value": "GARANTIA REVENDA", "key": "12"}',
			label: 'Garantia revenda'
		},
		{ value: '{"value": "GARANTIA", "key": "02"}', label: 'Garantia' },
		{ value: '{"value": "IMPRODUTIVO", "key": "77"}', label: 'Improdutivo' },
		{
			value: '{"value": "INSTALACAO REVENDA", "key": "17"}',
			label: 'Instalação revenda'
		},
		{ value: '{"value": "INSTALACAO", "key": "01"}', label: 'Instalação' },
		{ value: '{"value": "LOCACOES", "key": "08"}', label: 'Locações' },
		{ value: '{"value": "ORCAMENTOS", "key": "09"}', label: 'Orçamentos' },
		{
			value: '{"value": "OUTRAS SOLICITACOES CANAL CHEF", "key": "73"}',
			label: 'Outras solicitações canal chef'
		},
		{
			value: '{"value": "OUTRAS SOLICITACOES", "key": "18"}',
			label: 'Outras solicitações'
		},
		{
			value: '{"value": "PRE-INSTALACAO", "key": "16"}',
			label: 'Pré-Instalação'
		},
		{ value: '{"value": "PREVENTIVA", "key": "07"}', label: 'Preventiva' },
		{ value: '{"value": "RECALL", "key": "05"}', label: 'Recall' },
		{
			value: '{"value": "RETORNO AO CLIENTE", "key": "20"}',
			label: 'Retorno ao cliente'
		},
		{ value: '{"value": "SEMI NOVOS", "key": "13"}', label: 'Semi Novos' },
		{
			value:
				'{"value": "SOLICITACAO DE ATENDIMENTO VIA ASSISTENCIA TECNICA", "key": "71"}',
			label: 'Solicitação de atendimento via assistência técnica'
		},
		{
			value:
				'{"value": "SOLICITACAO DE ATENDIMENTO VIA ERRO ZERO", "key": "41"}',
			label: 'Solicitação de atendimento via erro zero'
		},
		{
			value:
				'{"value": "SOLICITACAO DE ATENDIMENTO VIA QUALIDADE", "key": "70"}',
			label: 'Solicitação de atendimento via qualidade'
		},
		{
			value:
				'{"value": "SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF)", "key": "74"}',
			label: 'Solicitação de treinamento bonificado (chef)'
		},
		{
			value:
				'{"value": "SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF+DESPESAS)", "key": "51"}',
			label: 'Solicitação de treinamento bonificado (chef+despesas)'
		},
		{
			value: '{"value": "SOLICITACAO DE TREINAMENTO POLITICA", "key": "30"}',
			label: 'Solicitação de treinamento politica'
		},
		{
			value:
				'{"value": "SOLICITACAO DE TREINAMENTO REVENDA (CHEF)", "key": "75"}',
			label: 'Solicitação de treinamento revenda (chef)'
		},
		{
			value:
				'{"value": "SOLICITACAO DE TREINAMENTO REVENDA (CHEF+DESPESAS)", "key": "76"}',
			label: 'Solicitação de treinamento revenda (chef+despesas)'
		},
		{
			value: '{"value": "SOLICITACAO DE TREINAMENTO REVENDA", "key": "72"}',
			label: 'Solicitação de treinamento revenda'
		},
		{
			value: '{"value": "VISITA DO CHEF VIA CONCESSIONARIA", "key": "31"}',
			label: 'Visita do chef via concessionária'
		},
		{
			value: '{"value": "VISITA DO CHEF VIA GERENTE/COORDENADOR", "key": "60"}',
			label: 'Visita do chef via gerente/coordenador'
		}
	]

	// if the user is not an Admin, remove the items below the options in "TypeOs"
	if (!userIsAdmin) {
		TypeOs = TypeOs.filter(
			(type) =>
				type.label !== 'Cortesia comercial' &&
				type.label !== 'Cortesia serviço técnico' &&
				type.label !== 'Fora de garantia' &&
				type.label !== 'Instalação' &&
				type.label !== 'Instalação revenda' &&
				type.label !== 'Garantia estendida'
		)
	}

	// if the equipment does not have an active preventive maintenance contract, remove the items below the options in "TypeOs"
	if (!preventiveIsActive) {
		TypeOs = TypeOs.filter(
			(type) =>
				type.label !== 'Preventiva' && type.label !== 'Corretiva preventiva'
		)
	}

	// if the equipment is not under warranty, remove the item below the options in "TypeOs"
	if (outWarranty) {
		TypeOs = TypeOs.filter((type) => type.label !== 'Garantia')
	}

	// returns the OS type selected via onSelectType
	function handleSelect(selectedTypeOS: string[]) {
		onSelectType(selectedTypeOS[0])
		registerType(JSON.parse(selectedTypeOS[0]).key, 'Z-')
	}

	const registerType = (key: string, table: string) => {
		getTypes('Z-')
			.then((res) => {
				const serviceTypeList = res.data
				const foundService = serviceTypeList.find(
					(item: any) => item.key === key
				)
				if (foundService) {
					if (!foundService.idFieldControl) {
						registerTypeOnPrp({ key: key, table: table })
					}
				} else {
					registerTypeOnPrp({ key, table })
				}
			})
			.catch((error) => {
				toast.error('Erro ao cadastrar tipo de serviço!.')
			})
	}

	const handleSelectType = () => {
		const typeConvert = TypeOs.filter((type) => {
			if (JSON.parse(type.value).value === selectedType) {
				return type.value
			}
		})[0]
		return typeConvert?.value
	}

	return (
		<div className="form-area">
			<Select
				innerRef={innerRef}
				id="service-type"
				onSelectOptions={handleSelect}
				options={TypeOs}
				selectedOption={[handleSelectType()]}
			/>
		</div>
	)
}

export default OptionsTypeOs
